<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="search" :rules="searchRules">
        <el-form-item>
          <div>
            <span>传感器号</span>
            <el-select v-model="search.sensorid" placeholder="关键字搜索" clearable filterable>
              <el-option v-for="item in searchOptions" :label="item.label" :value="item.value" :key="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <template>
            <span>异常时间点</span>
            <el-date-picker v-model="search.datetime" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="选择异常发生时间点">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <template>
            <span>处理时间段</span>
            <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="searchExceptionHandleList">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="resultList" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54">
      </el-table-column>
      <el-table-column prop="createdat" label="处理日期" width="150" sortable>
        <template slot-scope="scope">
          <span>{{new Date(scope.row.createdat).format('yyyy-mm-dd HH:MM')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="devicename" label="设备名称" min-width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="warehousename" label="库房" min-width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="sensorcode" label="传感器号" width="100">
      </el-table-column>
      <el-table-column prop="description" label="处理说明" min-width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="reason" label="异常原因" min-width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="result" label="处理结果" min-width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="person" label="处理人员" width="100" min-width="100">
      </el-table-column>
      <el-table-column prop="starttime" label="起始时间" width="150" min-width="150">
        <template slot-scope="scope">
          <span>{{new Date(scope.row.starttime).format('yyyy-mm-dd HH:MM')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="endtime" label="结束时间" width="150">
        <template slot-scope="scope">
          <span>{{new Date(scope.row.endtime).format('yyyy-mm-dd HH:MM')}}</span>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchOptions: [],
      search: {
        sensorid: "",
        datetime: null,
        daterange: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      total: 0,
      page: 1,
      listLoading: false,
      searchRules: {},
      resultList: []
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },

  methods: {
    formatTime: (row, column) => {
      return new Date(row.operdate).format("mm月dd日 HH:MM");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchExceptionHandleList();
    },
    //获取云传感器管理列表
    getCloudSensorsInfo() {
      let para = {
        customerid: this.customerid
      };
      this.listLoading = true;
      NProgress.start();
      this.axios.post("/datacenter/getcloudsensorslist", para).then(res => {
        this.listLoading = false;
        NProgress.done();
        if (res.data.code === 0) {
          let sensorlist = res.data.values.sensorslist.map(c => {
            return {
              label: c.sensorcode + "# " + c.devicename,
              value: c.sensorid
            };
          });
          this.searchOptions = sensorlist;
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    },
    searchExceptionHandleList() {
      let fromdate =
        this.search.daterange != null
          ? this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss")
          : "";
      let enddate =
        this.search.daterange != null
          ? this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss")
          : "";
      let datetime =
        this.search.datetime != null
          ? this.search.datetime.format("yyyy-mm-dd HH:MM:ss")
          : "";
      let sensorid = this.search.sensorid || null;
      let page = this.page;
      let params = {
        customerid: this.customerid,
        fromdate,
        enddate,
        datetime,
        sensorid,
        page
      };
      this.listLoading = true;
      NProgress.start();
      this.axios.post("/datacenter/exceptionhandlelist", params).then(res => {
        this.listLoading = false;
        NProgress.done();
        if (res.data.code === 0) {
          this.resultList = res.data.values.result;
          this.total = res.data.values.count;
        } else if (res.data.code === 113) {
          this.$message({
            message: "数据库错误：查询异常处理列表出错",
            type: "warning"
          });
        } else {
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    }
  },
  mounted() {
    this.getCloudSensorsInfo();
    this.searchExceptionHandleList();
  }
};
</script>
<style>

</style>