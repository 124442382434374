<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true">
        <el-form-item label="设备列表">
          <el-select placeholder="请选择设备" v-model="search.deviceid">
            <el-option-group v-for="group in deviceList" :key="group.label" :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.deviceid"
                :label="item.devicename"
                :value="item.deviceid"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template>
            <span>起止时间</span>
            <el-date-picker
              v-model="search.daterange"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            ></el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="searchGPSHistory" :disabled="searchUseable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!--地图-->
    <div id="allmap"></div>
  </section>
</template>
<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      BaiduMap: null,
      deviceList: [],
      listLoading: false,
      search: {
        deviceid: null,
        daterange: null
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365 *5  - 8.64e7; //最多五年前
        },
        shortcuts: [
          {
            text: "一小时前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
    searchUseable: function() {
      if (this.search.deviceid == "" || this.search.daterange == null)
        return true;
      else return false;
    }
  },
  methods: {
    mountCloudDevices() {
      this.axios
        .post("/datacenter/getdeviceslist", { customerid: this.customerid })
        .then(res => {
          /** 查询结果以deviceid为key 去重 */
          let devicesMap = new Map();
          res.data.values.deviceslist.map(c => {
            if (c.devicetype > 2) {
              devicesMap.set(c.deviceid, c);
            }
          });
          let devices = [...devicesMap.values()];
          /** 查询结果以deviceid为key 去重 END */
          let newArray = [
            { label: "保温箱", options: [] },
            { label: "冷藏车", options: [] },
            { label: "STL冷链箱", options: [] }
          ];
          devices.map(device => {
            if (device.devicetype == 3) {
              newArray[0].options.push(device);
            } else if (device.devicetype == 6) {
              newArray[2].options.push(device);
            } else {
              newArray[1].options.push(device);
            }
          });
          this.deviceList = newArray;
        });
    },
    searchGPSHistory() {
      let params = { customerid: this.customerid };
      params.deviceid = this.search.deviceid;
      params.starttime = this.search.daterange[0].format("yyyy-mm-dd HH:MM");
      params.endtime = this.search.daterange[1].format("yyyy-mm-dd HH:MM");
      this.axios.post("/datacenter/gpshistory", params).then(res => {
        if (res.data.values.result.length == 0) {
          return this.$message({
            type: "warning",
            message: "查询不到该时间段内的GPS数据"
          });
        } else {
          // 绘图处理
          this.BaiduMap.clearOverlays();
          let startPoint = null;
          let endPoint = null;
          let data = res.data.values.result.map((r, index, arr) => {
            // 移动到中心点
            if (index == Math.floor(arr.length / 2)) {
              this.BaiduMap.centerAndZoom(
                new BMap.Point(r.longitude, r.latitude),
                12
              );
            }
            if (index == 0) {
              startPoint = new BMap.Point(r.longitude, r.latitude);
            }
            if (index == arr.length - 1) {
              endPoint = new BMap.Point(r.longitude, r.latitude);
            }
            return new BMap.Point(r.longitude, r.latitude);
          });

          let polyline = new BMap.Polyline(data);
          this.BaiduMap.addOverlay(polyline);
          let startIcon = new BMap.Icon(
            "/depart_markers.png",
            new BMap.Size(30, 34)
          );
          let startMarker = new BMap.Marker(startPoint, { icon: startIcon });
          this.BaiduMap.addOverlay(startMarker);
          let endIcon = new BMap.Icon(
            "/dest_markers.png",
            new BMap.Size(30, 34)
          );
          let endMarker = new BMap.Marker(endPoint, { icon: endIcon });
          this.BaiduMap.addOverlay(endMarker);
        }
      });
    }
  },
  mounted() {
    this.mountCloudDevices();
    // 百度地图API功能
    var map = new BMap.Map("allmap"); // 创建Map实例
    map.centerAndZoom(new BMap.Point(116.404, 39.915), 13); // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    // 添加带有定位的导航控件
    let navigationControl = new BMap.NavigationControl({
      // 靠左上角位置
      anchor: BMAP_ANCHOR_TOP_LEFT,
      // LARGE类型
      type: BMAP_NAVIGATION_CONTROL_LARGE,
      // 启用显示定位
      enableGeolocation: true
    });
    map.addControl(navigationControl);
    // 添加定位控件
    var geolocationControl = new BMap.GeolocationControl();
    geolocationControl.addEventListener("locationSuccess", function(e) {
      // 定位成功事件
      let address = "";
      address += e.addressComponent.province;
      address += e.addressComponent.city;
      address += e.addressComponent.district;
      address += e.addressComponent.street;
      address += e.addressComponent.streetNumber;
      console.log("当前定位地址为：" + address);
    });
    geolocationControl.addEventListener("locationError", function(e) {
      // 定位失败事件
      console.log(e.message);
    });
    map.addControl(geolocationControl);
    this.BaiduMap = map;
  }
};
</script>
<style scope>
/* The container of BaiduMap must be set width & height. */

#allmap {
  width: 98%;
  margin: 0 auto;
  height: 680px;
  border: 1px #ccc solid;
}
</style>
