<template>
    <section>
        <!--工具条-->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
            <el-form :inline="true" :model="filters">
                <!-- <el-form-item>
                    <el-input v-model="filters.name" placeholder="报警器名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="getCloudDeviceInfo">查询</el-button>
                </el-form-item> -->
                <el-form-item>
                    <el-button :disabled="roletype > 4" type="primary" @click="handleClick('add')">新增</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <!--列表-->
        <el-table :data="getalarmdeviceList" style="width: 100%" stripe v-loading="listLoading">
            <el-table-column prop="DeviceName" label="报警器名称" width="200"></el-table-column>
            <el-table-column prop="Sensor_Alarmdevices" label="绑定传感器个数" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.Sensor_Alarmdevices.length }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="num" label="绑定传感器">
                <template slot-scope="scope">
                    <span v-for="item in scope.row.Sensor_Alarmdevices" :key="item.SensorId">
                        <el-tag>{{ sensorObj ? sensorObj[`${item.SensorId}`] : '-' }}</el-tag
                        >&nbsp;
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button :disabled="roletype > 4" size="small" @click="handleClick('edit', scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button> -->
                    <el-button :disabled="roletype > 4" type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--工具条-->
        <!--<el-col :span="24">
							<el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
							</el-pagination>
						</el-col>
    -->
        <!--弹窗界面-->
        <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false">
            <el-form :model="formData" label-width="100px" :rules="formRules" ref="formData">
                <el-form-item label="报警器" prop="deviceid">
                    <el-select v-model="formData.deviceid" placeholder="选择报警器" clearable>
                        <el-option v-for="item in alarmdevicemodels" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="绑定传感器" prop="sensorids" width="100%">
                    <template>
                        <el-select v-model="formData.sensorids" multiple placeholder="请选择传感器">
                            <el-option v-for="item in sensorsOptionList" :key="item.value" :label="item.label" :value="item.value" :disabled="!!item.alarmdeviceid"></el-option>
                        </el-select>
                    </template>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="visible = false">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import NProgress from 'nprogress'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            filters: {
                name: '',
            },
            getalarmdeviceList: [],
            allalarmdevicemodels: [],
            sensorsOptionList: [], //新增/编辑 传感器选择下拉列表
            originSensorsOptionList: [], //新增/编辑 传感器选择下拉列表
            sensorObj: {},
            total: 0,
            //  page: 1,
            listLoading: false,
            title: '',
            visible: false, //界面是否显示
            comfirmLoading: false,
            formRules: {
                deviceid: [
                    {
                        required: true,
                        message: '请选择报警器名称',
                        trigger: 'change',
                    },
                ],
                sensorids: [
                    {
                        type: 'array',
                        required: true,
                        message: '请选择绑定传感器',
                        trigger: 'change',
                    },
                ],
            },
            //编辑界面数据
            formData: {
                deviceid: '',
                alarmdevicename: '',
                sensorids: [],
            },
        }
    },
    computed: {
        ...mapGetters(['roletype', 'customerid']),
        alarmdevicemodels: function() {
            let ids = this.getalarmdeviceList.map((e) => e.DeviceId)
            let models =
                this.getalarmdeviceList && this.getalarmdeviceList.length
                    ? this.allalarmdevicemodels.filter((item) => {
                          if (!ids.includes(item.value)) return item
                      })
                    : this.allalarmdevicemodels
            console.log('🚀 ~ file: alarmdevice.vue ~ line 128 ~ models', models)
            return models
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val
            this.getCloudDeviceInfo()
        },
        formatCreatedAt: (row) => {
            return new Date(row.createdat).format('yyyy年mm月dd日')
        },

        //获取云设备列表
        getCloudDeviceInfo() {
            let para = {
                customerid: this.customerid,
                name: this.filters.name,
            }
            this.listLoading = true
            NProgress.start()
            this.axios.post('/cloudmanagement/getcloudsensors', para).then((reply) => {
                this.originSensorsOptionList = this.sensorsOptionList = reply.data.values.sensors
                reply.data.values.sensors.forEach((sensor) => {
                    this.sensorObj[sensor.value] = sensor.label
                })
            })

            this.axios.post('/cloudmanagement/alarmdevicerules', para).then((reply) => {
                this.listLoading = false
                NProgress.done()
                if (reply.data.code === 0) {
                    this.getalarmdeviceList = reply.data.values.rows
                } else {
                    this.$message({
                        message: `查询出错，错误码${reply.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },
        //查询报警器
        getAlarmDeviceModel() {
            let para = {
                customerid: this.customerid,
            }
            this.axios.post('/cloudmanagement/getalarmdevices', para).then((reply) => {
                this.allalarmdevicemodels = reply.data.values.devices
            })
        },
        //删除
        handleDel: function(index, row) {
            this.$confirm('确认提交吗？', '提示', {}).then(() => {
                let params = {
                    customerid: this.customerid,
                    deviceid: row.DeviceId,
                }
                this.listLoading = true
                NProgress.start()
                this.axios.post('/cloudmanagement/deletealarmdevicerule', params).then((res) => {
                    this.listLoading = false
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getCloudDeviceInfo()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },
        //显示编辑界面
        handleClick: function(type, row) {
            this.title = type == 'add' ? '新增' : '编辑'
            this.visible = true
            // 先深拷贝还原初始值
            this.formData = {
                deviceid: '',
                alarmdevicename: '',
                sensorids: [],
            }
            this.sensorsOptionList = JSON.parse(JSON.stringify(this.originSensorsOptionList))
            this.$nextTick(() => {
                this.$refs['formData'].resetFields()
            })
            if (row) {
                this.formData = Object.assign(this.formData, row)
                this.formData.sensorids = []
                row.sensorslist.map((sensor) => {
                    this.formData.sensorids.push(sensor.sensorid)
                })
                this.sensorsOptionList.forEach((sensor) => {
                    row.sensorslist.map((s) => {
                        if (s.sensorid == sensor.value) {
                            sensor.alarmdeviceid = false
                        }
                    })
                    return sensor
                })
            }
        },
        //编辑
        handleSubmit: function() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.comfirmLoading = true
                        NProgress.start()
                        let params = {}
                        Object.assign(params, this.formData)
                        this.axios.post('/cloudmanagement/addalarmdevicerule', params).then((res) => {
                            this.comfirmLoading = false
                            NProgress.done()
                            if (res.data.code === 0) {
                                this.$notify({
                                    title: '成功',
                                    message: '新增成功',
                                    type: 'success',
                                })
                                this.$refs['formData'].resetFields()
                                this.visible = false
                                this.getCloudDeviceInfo()
                            } else if (res.data.code === 102) {
                                this.$message({
                                    message: '您的权限不足',
                                    type: 'warning',
                                })
                            } else {
                                this.$message({
                                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                                    type: 'warning',
                                })
                            }
                        })
                    })
                }
            })
        },
    },
    mounted() {
        this.getCloudDeviceInfo()
        this.getAlarmDeviceModel()
    },
}
</script>
<style scope>
.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
