<!--
 * @Date: 2022-07-28 10:59:21
 * @LastEditors: zhouhua
 * @LastEditTime: 2022-07-29 13:53:20
 * @FilePath: \coldcloud-vue-web\src\pages\alarmphone.vue
-->
<template>
    <section>
        <!--工具条-->
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
            <el-form :inline="true">
                <el-form-item>
                    <el-button @click="$router.back()">返回</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="dataFormShow('add')">新增</el-button>
                </el-form-item>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table :data="resultList" v-loading="listLoading" stripe highlight-current-row>
            <el-table-column type="index" width="65"> </el-table-column>
            <el-table-column prop="Name" label="姓名" sortable> </el-table-column>
            <el-table-column prop="Phone" label="号码"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="150" min-width="150">
                <template slot-scope="scope">
                    <el-button size="small" @click="dataFormShow('edit', scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button type="warning" size="small" @click="handleDel(scope.row)">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--编辑运输超时报警界面-->
        <el-dialog :title="title" :visible.sync="dataFormVisible" :close-on-click-modal="false">
            <el-form :model="formData" label-width="150px" :rules="dataFormRules" ref="dataForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input v-model="formData.phone" :disabled="!!formData.id"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dataFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="handleSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import NProgress from 'nprogress'
export default {
    data() {
        var validatePhone = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请输入11位手机号码'))
            }
            if (!/^((\+|00)86)?1([3589][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(value)) callback(new Error('请输入正确的手机号码'))
            else {
                callback()
            }
        }
        return {
            listLoading: false,
            resultList: [],
            dataFormVisible: false,
            title: '',
            formType: {
                add: '新增',
                edit: '编辑',
            },
            formData: {
                name: '',
                phone: '',
            },
            comfirmLoading: false,
            dataFormRules: {
                name: {
                    required: true,
                    trigger: 'blur',
                },
                phone: [
                    {
                        required: true,
                        validator: validatePhone,
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {
        ...mapGetters(['customerid']),
    },
    methods: {
        getList() {
            this.listLoading = true
            NProgress.start()
            this.axios.post('/user/shortcutlist', { customerid: this.customerid }).then((res) => {
                this.listLoading = false
                NProgress.done()
                if (res.data.code === 0) {
                    this.resultList = res.data.values.shortcutlist
                } else {
                    this.$message({
                        message: `查询出错，错误码${res.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },

        //显示弹窗界面
        dataFormShow: function(type, row) {
            this.dataFormVisible = true
            this.title = this.formType[type]
            if (type == 'edit') {
                this.formData = {
                    id: row.Id,
                    name: row.Name,
                    phone: row.Phone,
                }
            } else {
                this.formData = {
                    name: '',
                    phone: '',
                }
            }
        },

        //提交
        handleSubmit: function() {
            this.$refs.dataForm.validate((valid) => {
                let params = {}
                Object.assign(params, this.formData, {
                    customerid: this.customerid,
                })

                if (valid) {
                    this.comfirmLoading = true
                    NProgress.start()
                    let api = !params.id ? 'user/addshortcut' : 'user/editshortcut'
                    this.axios
                        .post(api, params)
                        .then((res) => {
                            this.comfirmLoading = false
                            NProgress.done()
                            if (res.data.code === 0) {
                                this.$notify({
                                    title: '成功',
                                    message: params.id ? '编辑成功' : '新增成功',
                                    type: 'success',
                                })
                                this.$refs['dataForm'].resetFields()
                                this.dataFormVisible = false
                                this.getList()
                            } else if (res.data.code === 102) {
                                this.$message({
                                    message: '您的权限不足',
                                    type: 'warning',
                                })
                            } else {
                                this.$message({
                                    message: `保存失败，错误码${res.data.code},请联系管理员`,
                                    type: 'warning',
                                })
                            }
                        })
                        .catch((e) => {
                            console.log('🚀 ~ file: DeliverAlarm.vue ~ line 371 ~ this.$confirm ~ e', e)
                        })
                        .finally(() => {
                            this.comfirmLoading = false
                        })
                }
            })
        },

        //删除运输超时
        handleDel: function(row) {
            this.$confirm('确认删除该信息吗?', '提示', {
                type: 'warning',
            }).then(() => {
                let params = {
                    id: row.Id,
                }
                NProgress.start()
                this.axios.post('user/deleteshortcut', params).then((res) => {
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getList()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },
    },
    mounted() {
        this.getList()
    },
}
</script>
<style>
.phoneLink {
    float: right;
}
</style>
