<template>
  <section>
    <!--工具条-->
    <el-col class="toolbar" :span="24" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="search" :rules="searchRules">
        <el-form-item>
          <template>
            <span>起止时间</span>
            <el-date-picker v-model="search.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
          </template>
        </el-form-item>

        <el-form-item>
          <el-button v-on:click="resetPageAndSearch" :disabled="searchUseable">查询</el-button>
        </el-form-item>

      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="resultList" v-loading="listLoading" stripe highlight-current-row show-summary :summary-method="getSummaries">
      <el-table-column type="index" width="65">
      </el-table-column>
      <el-table-column prop="sendtime" :formatter="formatTime" label="发送时间" width="180" sortable>
      </el-table-column>
      <el-table-column prop="type" label="报警类型" :formatter="formatType" width="120" sortable>
      </el-table-column>
      <el-table-column prop="phonenum" label="手机号码" min-width="150" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="content" label="短信内容" min-width="250" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="count" label="短信条数" min-width="100">
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total"  :current-page="page" style="float:right;">
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import NProgress from "nprogress";
export default {
  data() {
    return {
      search: {
        daterange: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "一天前",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近半个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      total: 0,
      page: 1,
      listLoading: false,
      searchRules: {},
      resultList: []
    };
  },
  computed: {
    ...mapGetters(["customerid"]),
    searchUseable: function() {
      if (this.search.daterange == "" || this.search.daterange == null)
        return true;
      else return false;
    }
  },
  methods: {
    formatTime: (row, column) => {
      return new Date(row.sendtime).format("mm月dd日 HH:MM:ss");
    },
    formatType: (row, column) => {
      switch(row.type) {
        case 1:
        case 4:
          return " 超标报警";
        case 3:
          return " 断电报警";

        case 2:
          return "故障报警";
        case 6:
          return " 预警";
        case 8:
          return "低电量报警";

        default:
          return "离线报警";
      } 

     // return row.type == 3 ? "断电报警" : row.type == 2 ? "故障报警" : "超标报警";
    },

    resetPageAndSearch(){
      this.page = 1
      this.searchSMSList()
    },

    handleCurrentChange(val) {
      this.page = val;
      this.searchSMSList();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
                  console.log(column)

        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //只统计短信条数
        if (index == 5) {

          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "条";
          } else {
            sums[index] = "N/A";
          }
        }
      });
      return sums;
    },
    searchSMSList() {
      if (
        this.search.daterange[1] - this.search.daterange[0] >
        90 * 24 * 3600 * 1000
      ) {
        return this.$message({
          message: `查询时间跨度过大，请选择3个月内的时间范围。`,
          type: "warning"
        });
      }
      let fromdate = this.search.daterange[0].format("yyyy-mm-dd HH:MM:ss");
      let todate = this.search.daterange[1].format("yyyy-mm-dd HH:MM:ss");
      let page = this.page;
      let customerid = this.customerid;
      let params = {
        fromdate,
        todate,
        page,
        customerid
      };
      this.listLoading = true;

      NProgress.start();
      this.axios.post("/datacenter/smslog", params).then(res => {
        this.listLoading = false;
        NProgress.done();
        if (res.data.code === 0) {
          this.resultList = res.data.values.result;
          this.total = res.data.values.count;
        } else {
          console.log(JSON.stringify(res.data.error));
          this.$message({
            message: `查询出错，错误码${res.data.code},请联系管理员`,
            type: "warning"
          });
        }
      });
    }
  },
  mounted() {}
};
</script>
<style>

</style>