<template>
    <section>
        <el-col :span="24" class="toolbar" style="padding-bottom: 0px;  position: relative;">
            <el-tabs v-model="tabSelected" class="el-col el-col-24">
                <el-tab-pane label="断电报警" name="first">
                    <el-table :data="blackoutalarmlist" v-loading="OverlistLoading" style="width: 100%;" stripe highlight-current-row>
                        <el-table-column prop="devicename" label="设备名称" min-width="150" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="devicecode" label="设备号" min-width="130" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="phone" label="报警短信号码" min-width="180" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.phone | phoneFilter(phoneObj) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="issmsenable" label="短信报警" :formatter="formatSMSEnable" min-width="150"></el-table-column>
                        <el-table-column prop="isvmsenable" label="语音报警" :formatter="formatVMSEnable" min-width="150"></el-table-column>
                        <el-table-column prop="iswechatenable" label="微信报警" :formatter="formatWeChatEnable" min-width="150"></el-table-column>
                        <el-table-column label="操作" fixed="right" width="150" min-width="150">
                            <template slot-scope="scope">
                                <el-button :disabled="roletype > 4" size="small" @click="blackoutFormShow('edit', scope.row)">
                                    <i class="el-icon-edit"></i>
                                </el-button>
                                <el-button :disabled="roletype > 4" type="warning" size="small" @click="handleBlackoutDel(scope.row)">
                                    <i class="el-icon-delete"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--工具条-->
                    <el-col :span="24" class="toolbar">
                        <el-button type="primary" @click="blackoutFormShow('add')" :disabled="roletype > 4">新增</el-button>
                    </el-col>
                </el-tab-pane>
                <el-tab-pane label="离线报警" name="second">
                    <el-table :data="offlinealarmlist" v-loading="OverlistLoading" style="width: 100%;" stripe highlight-current-row>
                        <el-table-column prop="devicename" label="设备名称" min-width="150" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="devicecode" label="设备号" min-width="130" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="alarmphone" label="报警短信号码" min-width="180" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.alarmphone | phoneFilter(phoneObj) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="issmsenable" label="短信报警" :formatter="formatSMSEnable" min-width="150"></el-table-column>
                        <el-table-column prop="isvmsenable" label="语音报警" :formatter="formatVMSEnable" min-width="150"></el-table-column>
                        <el-table-column prop="iswechatenable" label="微信报警" :formatter="formatWeChatEnable" min-width="150"></el-table-column>
                        <el-table-column prop="alarminterval" label="报警间隔(分)" min-width="150"></el-table-column>
                        <el-table-column prop="threshold" label="离线判别间隔(分)" min-width="150"></el-table-column>
                        <el-table-column label="操作" fixed="right" width="150" min-width="150">
                            <template slot-scope="scope">
                                <el-button :disabled="roletype > 4" size="small" @click="offlineFormShow('edit', scope.row)">
                                    <i class="el-icon-edit"></i>
                                </el-button>
                                <el-button :disabled="roletype > 4" type="warning" size="small" @click="handleOfflineDel(scope.$index, scope.row)">
                                    <i class="el-icon-delete"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--工具条-->
                    <el-col :span="24" class="toolbar">
                        <el-button type="primary" @click="offlineFormShow('add')" :disabled="roletype > 4">新增</el-button>
                    </el-col>
                </el-tab-pane>
            </el-tabs>
        </el-col>
        <router-link to="/alarmphone">
            <el-button type="text" class="phoneLink">
                报警号码管理
            </el-button>
        </router-link>

        <!--断电报警界面-->
        <el-dialog :title="blackoutTitle" :visible.sync="blackoutFormVisible" :close-on-click-modal="false">
            <el-form :model="blackoutForm" label-width="120px" :rules="blackoutFormRules" ref="blackoutForm">
                <el-form-item label="所属设备" prop="deviceid">
                    <el-select v-model="blackoutForm.deviceid" auto-complete="off">
                        <el-option v-for="item in devicemodels" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报警号码" prop="phone">
                    <el-select v-model="blackoutForm.phone" multiple filterable allow-create :filter-method="handleFilter" placeholder="请选择">
                        <el-option v-for="item in filterOptions" :key="item.name" :label="item.name" :value="item.phone"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="启用短信" prop="issmsenable" required>
                    <el-switch v-model="blackoutForm.issmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用语音" prop="isvmsenable" required>
                    <el-switch v-model="blackoutForm.isvmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用微信" prop="iswechatenable" required>
                    <el-switch v-model="blackoutForm.iswechatenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <!-- <el-form-item label="启用APP" prop="isappenable" required>
          <el-switch v-model="blackoutForm.isappenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
        </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="blackoutFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="blackoutSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--离线报警界面-->
        <el-dialog :title="offlineTitle" :visible.sync="offlineFormVisible" :close-on-click-modal="false">
            <el-form :model="offlineForm" label-width="120px" :rules="offlineFormRules" ref="offlineForm">
                <el-form-item label="所属设备" prop="deviceid">
                    <el-select v-model="offlineForm.deviceid" auto-complete="off">
                        <el-option v-for="item in offlinedevicemodels" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报警号码" prop="alarmphone">
                    <el-select v-model="offlineForm.alarmphone" multiple filterable allow-create :filter-method="handleFilter" placeholder="请选择">
                        <el-option v-for="item in filterOptions" :key="item.name" :label="item.name" :value="item.phone"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="启用短信" prop="issmsenable" required>
                    <el-switch v-model="offlineForm.issmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用语音" prop="isvmsenable" required>
                    <el-switch v-model="offlineForm.isvmsenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="启用微信" prop="iswechatenable" required>
                    <el-switch v-model="offlineForm.iswechatenable" active-color="#20a0ff" inactive-color="#bfcbd9"></el-switch>
                </el-form-item>
                <el-form-item label="连续报警间隔" required>
                    <el-select v-model="offlineForm.alarminterval" placeholder="请选择">
                        <el-option v-for="item in alarmTimeOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>
                    <span>分钟</span>
                </el-form-item>
                <el-form-item label="离线判别间隔" required>
                    <el-select v-model="offlineForm.threshold" placeholder="请选择">
                        <el-option v-for="item in thresholdTimeOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
                    </el-select>
                    <span>分钟</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="offlineFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="offlineSubmit" :loading="comfirmLoading">提交</el-button>
            </div>
        </el-dialog>
    </section>
</template>
<script>
import NProgress from 'nprogress'
import { mapGetters } from 'vuex'
import validator from 'validator'
export default {
    filters: {
        phoneFilter(phone, phoneObj) {
            let newArr = phone.split(',').map((e) => (Object.prototype.hasOwnProperty.call(phoneObj, e) ? phoneObj[e] : e))
            return newArr.join()
        },
    },
    data() {
        const validatorPhone = (rule, value, callback) => {
            console.log(value)
            if (value.length > 0) {
                value.forEach((phone) => {
                    if (Number(phone) === +phone && !validator.isMobilePhone(phone, 'zh-CN')) {
                        return callback(new Error('请输入正确的手机号码'))
                    }
                })
            }
            return callback()
        }
        return {
            tabSelected: 'first',
            devicemodels: [],
            origindevicemodels: [],
            blackoutalarmlist: [],
            offlinealarmlist: [],
            offlinedevicemodels: [],
            originofflinedevicemodels: [],
            total: 0,
            page: 1,

            OverlistLoading: false,
            //报警间隔
            alarmTimeOptions: [
                {
                    label: 30,
                },
                {
                    label: 60,
                },
                {
                    label: 360,
                },
            ],
            // 离线判别间隔
            thresholdTimeOptions: [
                {
                    label: 30,
                },
                {
                    label: 60,
                },
                {
                    label: 90,
                },
            ],

            blackoutFormVisible: false, //编辑界面是否显示
            offlineFormVisible: false, //编辑界面是否显示
            comfirmLoading: false,

            //编辑断电界面数据
            blackoutForm: {
                deviceid: '',
                phone: '',
                issmsenable: true,
                isvmsenable: true,
                iswechatenable: true,
            },
            blackoutFormRules: {
                deviceid: [
                    {
                        required: true,
                        type: 'number',
                        message: '请选择所属设备',
                        trigger: 'change',
                    },
                ],
                phone: [
                    {
                        validator: validatorPhone,
                    },
                ],
            },

            addLoading: false,

            blackoutTitle: '',
            blackoutType: {
                add: '新增断电报警',
                edit: '编辑断电报警',
            },
            offlineTitle: '',
            offlineType: {
                add: '新增离线报警',
                edit: '编辑离线报警',
            },

            //编辑离线界面数据
            offlineForm: {
                deviceid: '',
                alarmphone: '',
                issmsenable: true,
                isvmsenable: true,
                iswechatenable: true,
                alarminterval: 30,
                threshold: 30,
            },
            offlineFormRules: {
                deviceid: [
                    {
                        required: true,
                        type: 'number',
                        message: '请选择所属设备',
                        trigger: 'change',
                    },
                ],
                alarmphone: [
                    {
                        validator: validatorPhone,
                    },
                ],
            },

            editedsensorid: [],
            originPhoneList: [], //所有报警号码
            phoneObj: {}, //报警号码对象
            filterOptions: [],
        }
    },
    computed: {
        ...mapGetters(['roletype', 'customerid']),
    },
    methods: {
        formatSMSEnable: (row) => {
            return row.issmsenable ? '启用' : '停用'
        },
        formatVMSEnable: (row) => {
            return row.isvmsenable ? '启用' : '停用'
        },
        formatWeChatEnable: (row) => {
            return row.iswechatenable ? '启用' : '停用'
        },
        handleCurrentChange(val) {
            this.page = val
            this.getCloudblackoutAlarmInfo()
        },

        getUserList() {
            this.axios.post('/user/shortcutlist', { customerid: this.customerid }).then((res) => {
                if (res.data.code === 0) {
                    this.originPhoneList = this.filterOptions = res.data.values.shortcutlist.map((e) => {
                        let obj = {
                            name: e.Name,
                            phone: e.Phone,
                        }
                        return obj
                    })
                    res.data.values.shortcutlist.forEach((e) => {
                        this.phoneObj[e.Phone] = e.Name
                    })
                } else {
                    this.$message({
                        message: `查询出错，错误码${res.data.code},请联系管理员`,
                        type: 'warning',
                    })
                }
            })
        },
        // 号码人员筛选
        handleFilter(val) {
            console.log(val)
            let restaurants = this.originPhoneList
            let results = val ? restaurants.filter((e) => e.name.includes(val) || e.phone.includes(val)) : restaurants
            console.log('results', results)
            this.filterOptions = results
        },

        validateSMSPhone(para) {
            console.log('🚀 ~ file: localalarmdevice.vue ~ line 363 ~ validateSMSPhone ~ para', para)
            //短信报警开启且短信报警手机号为空
            if (para.issmsenable || para.isvmsenable) {
                if (para.phone != undefined) {
                    if (para.phone.length == 0) {
                        return false
                    }
                }
                if (para.alarmphone != undefined) {
                    if (para.alarmphone.length == 0) {
                        return false
                    }
                }
            }
            return true
        },
        //获取断电报警数据
        getCloudBlackoutAlarmInfo() {
            let para = {
                customerid: this.customerid,
            }
            this.OverlistLoading = true
            NProgress.start()
            this.axios.post('/localmanagement/getlocalalarmdevicelist', para).then((reply) => {
                this.origindevicemodels = this.devicemodels = reply.data.values.devices

                this.axios.post('/localmanagement/getblackoutalarmruleslist', para).then((reply) => {
                    this.OverlistLoading = false
                    NProgress.done()
                    if (reply.data.code === 0) {
                        if (reply.data.total === 0)
                            this.$message({
                                message: `没有数据`,
                                type: 'warning',
                            })
                        this.blackoutalarmlist = reply.data.values.ruleslist
                        this.origindevicemodels = this.devicemodels.map((s) => {
                            s.disabled = false
                            this.blackoutalarmlist.map((rule) => {
                                if (rule.deviceid == s.value) {
                                    s.disabled = true
                                }
                            })
                            return s
                        })
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },

        //获取离线报警数据
        getOfflineAlarmInfo() {
            let para = {
                customerid: this.customerid,
            }
            this.OverlistLoading = true
            NProgress.start()
            this.axios.post('/localmanagement/getlocaldevices', para).then((reply) => {
                this.originofflinedevicemodels = this.offlinedevicemodels = reply.data.values.devices
                this.axios.post('/localmanagement/getofflinealarmruleslist', para).then((reply) => {
                    this.OverlistLoading = false
                    NProgress.done()
                    if (reply.data.code === 0) {
                        this.offlinealarmlist = reply.data.values.rulelist
                        this.originofflinedevicemodels = this.offlinedevicemodels.map((s) => {
                            s.disabled = false
                            this.offlinealarmlist.map((rule) => {
                                if (rule.deviceid == s.value) {
                                    s.disabled = true
                                }
                            })
                            return s
                        })
                    } else {
                        this.$message({
                            message: `查询出错，错误码${reply.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },

        //删除断电
        handleBlackoutDel: function(row) {
            this.$confirm('确认删除该设备吗?', '提示', {
                type: 'warning',
            }).then(() => {
                let params = {}
                params = Object.assign({}, row)
                this.OverlistLoading = true
                NProgress.start()
                this.axios.post('/localmanagement/deleteblackoutalarmrule', params).then((res) => {
                    this.OverlistLoading = false
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getCloudBlackoutAlarmInfo()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },

        //显示断电新增/编辑界面
        blackoutFormShow: function(type, row) {
            this.blackoutFormVisible = true
            this.blackoutTitle = this.blackoutType[type]
            if (type == 'edit') {
                this.blackoutForm = Object.assign(this.blackoutForm, row)
                this.blackoutForm.issmsenable = !!row.issmsenable
                this.blackoutForm.isvmsenable = !!row.isvmsenable
                this.blackoutForm.iswechatenable = !!row.iswechatenable
                this.blackoutForm.isappenable = !!row.isappenable
                this.blackoutForm.deviceid = Number(row.deviceid)
                this.blackoutForm.phone = row.phone.split(',')
            } else {
                this.blackoutForm = {
                    deviceid: '',
                    phone: '',
                    issmsenable: true,
                    isvmsenable: true,
                    iswechatenable: true,
                    isappenable: true,
                }
            }

            // 先深拷贝还原初始值
            this.devicemodels = JSON.parse(JSON.stringify(this.origindevicemodels))
            row &&
                this.devicemodels.forEach((s) => {
                    if (row.deviceid == s.value) {
                        s.disabled = false
                    }
                    return s
                })
            this.$nextTick(() => {
                this.$refs['blackoutForm'].clearValidate()
            })
        },

        //断电提交
        blackoutSubmit: function() {
            this.$refs.blackoutForm.validate((valid) => {
                let params = {}
                Object.assign(params, this.blackoutForm)
                if (!this.validateSMSPhone(params)) {
                    return this.$message({
                        message: `必须输入手机号码`,
                        type: 'warning',
                    })
                }
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.comfirmLoading = true
                        NProgress.start()
                        params.phone = params.phone.length ? params.phone.join(',') : ''
                        params.issmsenable = params.issmsenable ? 1 : 0
                        params.isvmsenable = params.isvmsenable ? 1 : 0
                        params.iswechatenable = params.iswechatenable ? 1 : 0
                        params.isappenable = params.isappenable ? 1 : 0
                        this.devicemodels.map((dev) => {
                            if (dev.value == params.deviceid) {
                                params.devicecode = dev.code
                            }
                        })
                        let api = !params.ruleid ? '/localmanagement/addblackoutalarmrule' : '/localmanagement/editblackoutalarmrule'
                        this.axios
                            .post(api, params)
                            .then((res) => {
                                this.comfirmLoading = false
                                NProgress.done()
                                if (res.data.code === 0) {
                                    this.$notify({
                                        title: '成功',
                                        message: params.ruleid ? '编辑成功' : '新增成功',
                                        type: 'success',
                                    })
                                    this.$refs['blackoutForm'].resetFields()
                                    this.blackoutFormVisible = false
                                    this.getCloudBlackoutAlarmInfo()
                                } else if (res.data.code === 102) {
                                    this.$message({
                                        message: '您的权限不足',
                                        type: 'warning',
                                    })
                                } else {
                                    this.$message({
                                        message: `保存失败，错误码${res.data.code},请联系管理员`,
                                        type: 'warning',
                                    })
                                }
                            })
                            .catch((e) => {})
                            .finally(() => {
                                this.comfirmLoading = false
                            })
                    })
                }
            })
        },

        //删除离线
        handleOfflineDel: function(index, row) {
            this.$confirm('确认删除该设备吗?', '提示', {
                type: 'warning',
            }).then(() => {
                let params = {
                    ruleid: row.ruleid,
                }

                this.OverlistLoading = true
                NProgress.start()
                this.axios.post('/localmanagement/deleteofflinealarmrule', params).then((res) => {
                    this.OverlistLoading = false
                    NProgress.done()
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '删除成功',
                            type: 'success',
                        })
                        this.getOfflineAlarmInfo()
                    } else if (res.data.code === 102) {
                        this.$message({
                            message: '您的权限不足',
                            type: 'warning',
                        })
                    } else {
                        this.$message({
                            message: `删除失败，错误码${res.data.code},请联系管理员`,
                            type: 'warning',
                        })
                    }
                })
            })
        },

        //显示离线新增编辑界面
        offlineFormShow: function(type, row) {
            this.offlineFormVisible = true
            this.offlineTitle = this.offlineType[type]
            if (type == 'edit') {
                this.offlineForm = Object.assign(this.offlineForm, row)
                this.offlineForm.issmsenable = !!row.issmsenable
                this.offlineForm.isvmsenable = !!row.isvmsenable
                this.offlineForm.iswechatenable = !!row.iswechatenable
                this.offlineForm.deviceid = Number(row.deviceid)
                this.offlineForm.alarmphone = row.alarmphone.split(',')
            } else {
                this.offlineForm = {
                    deviceid: '',
                    alarmphone: '',
                    issmsenable: true,
                    isvmsenable: true,
                    iswechatenable: true,
                    alarminterval: 30,
                    threshold: 30,
                }
            }

            // 先深拷贝还原初始值
            this.offlinedevicemodels = JSON.parse(JSON.stringify(this.originofflinedevicemodels))
            row &&
                this.offlinedevicemodels.forEach((s) => {
                    if (row.deviceid == s.value) {
                        s.disabled = false
                    }
                    return s
                })
            this.$nextTick(() => {
                this.$refs['offlineForm'].clearValidate()
            })
        },

        //离线编辑
        offlineSubmit: function() {
            this.$refs.offlineForm.validate((valid) => {
                let params = {}
                Object.assign(params, this.offlineForm)
                if (!this.validateSMSPhone(params)) {
                    return this.$message({
                        message: `必须输入手机号码`,
                        type: 'warning',
                    })
                }
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.comfirmLoading = true
                        NProgress.start()
                        params.issmsenable = params.issmsenable ? 1 : 0
                        params.isvmsenable = params.isvmsenable ? 1 : 0
                        params.iswechatenable = params.iswechatenable ? 1 : 0
                        params.alarmphone = params.alarmphone.length ? params.alarmphone.join(',') : ''
                        let api = !params.ruleid ? '/localmanagement/addofflinealarmrule' : '/localmanagement/editofflinealarmrule'
                        this.axios
                            .post(api, params)
                            .then((res) => {
                                this.comfirmLoading = false
                                NProgress.done()
                                if (res.data.code === 0) {
                                    this.$notify({
                                        title: '成功',
                                        message: params.ruleid ? '编辑成功' : '新增成功',
                                        type: 'success',
                                    })
                                    this.$refs['offlineForm'].resetFields()
                                    this.offlineFormVisible = false
                                    this.getOfflineAlarmInfo()
                                } else if (res.data.code === 102) {
                                    this.$message({
                                        message: '您的权限不足',
                                        type: 'warning',
                                    })
                                } else {
                                    this.$message({
                                        message: `保存失败，错误码${res.data.code},请联系管理员`,
                                        type: 'warning',
                                    })
                                }
                            })
                            .catch((e) => {})
                            .finally(() => {
                                this.comfirmLoading = false
                            })
                    })
                }
            })
        },
    },
    mounted() {
        this.getUserList()
        this.getCloudBlackoutAlarmInfo()
        this.getOfflineAlarmInfo()
        let para = {
            customerid: this.customerid,
        }
        this.axios.post('/localmanagement/getlocalalarmdevicelist', para).then((reply) => {
            this.devicemodels = reply.data.values.devices
        })
    },
}
</script>
